<template>
  <div class="bg-white" style="min-height: 87vh;">
    <div class="padding flex justify-content-between">
      <div>
        <el-button type="primary" @click="add">添加字典</el-button>
        <el-button type="danger" icon="el-icon-delete">批量删除</el-button>
         <el-select class="margin-left" v-model="query.module" filterable clearable placeholder="请选择模块类型" @change="selChange">
            <el-option
              v-for="item in ModuleList"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
      </div>

      <el-input placeholder="关键字查询" v-model="query.KeyWord" class="input-with-select" clearable style="width: 300px;">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <div class="padding">
      <el-table :data="tableData"  style="width: 100%" border row-key="id" lazy :tree-props="{  children:'children',hasChildren: 'leaf' }">
        <el-table-column prop="id" align="center" label="id" sortable></el-table-column>
        <el-table-column prop="module" align="center" label="模块类型"></el-table-column>
        <el-table-column prop="title" align="center" label="栏目标题"></el-table-column>
        <el-table-column prop="sub_title" align="center" label="子标题"></el-table-column>
        <el-table-column prop="remark" align="center" label="栏目描述"></el-table-column>
        <el-table-column prop="d_level" align="center" label="栏目级别"></el-table-column>
        <el-table-column prop="parent_id" align="center" label="父级ID"></el-table-column>
        <el-table-column prop="school_id" align="center" label="校区id"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="215">
          <template slot-scope="scope">
            <div class="flex">
              <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
              <el-button type="text" size="small" @click="addSubordinate(scope.row)">添加下级</el-button>
              <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="permissionFormVisible = true">设置权限</el-button>
              <el-button type="text" size="small" @click="delClick(scope.row)"><i class="el-icon-delete text-red" /></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-content-center margin-top">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="query.PageIndex"
          :page-sizes="[10, 15, 20, 50]"
          :page-size="query.PageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 删除字典 -->
    <el-dialog title="提示" :visible.sync="delDialog" width="30%" :before-close="handleClose">
      <span>是否确认删除</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialog = false">取 消</el-button>
        <el-button type="primary" @click="del()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加字典 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
      <el-form :model="dialogFrom">
        <el-form-item label="模块类型(英文)" :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.module" autocomplete="off" /></el-form-item>
        <el-form-item label="栏目标题" :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.title" autocomplete="off" /></el-form-item>
        <el-form-item label="子标题 " :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.sub_title" autocomplete="off" /></el-form-item>
        <el-form-item label="栏目描述" :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.remark" autocomplete="off" /></el-form-item>
        <el-form-item label="栏目级别" :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.d_level" autocomplete="off" /></el-form-item>
        <el-form-item label="父级ID" :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.parent_id" autocomplete="off" /></el-form-item>
        <el-form-item label="校区id" :label-width="formLabelWidth"><el-input clearable v-model="dialogFrom.school_id" autocomplete="off" /></el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设置字典权限 -->
    <el-dialog title="设置权限" :visible.sync="permissionFormVisible">
      <el-form :model="permissionForm">
        <el-form-item label="字典id" :label-width="formLabelWidth"><el-input clearable v-model="permissionForm.id" autocomplete="off" /></el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth"><el-input clearable v-model="permissionForm.RoleIs" autocomplete="off" /></el-form-item>
        <el-form-item label="账号id " :label-width="formLabelWidth"><el-input clearable v-model="permissionForm.UserIds" autocomplete="off" /></el-form-item>
        <el-form-item label="组织id" :label-width="formLabelWidth"><el-input clearable v-model="permissionForm.OrgIds" autocomplete="off" /></el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      tableData: [],
      dialogFormVisible: false,
      dialogTitle: '',
      form: {
        id: '',
        title: '',
        sub_title: '',
        remark: '',
        d_level: 0,
        parent_id: 0,
        // is_menue: true, // 是否菜单
        // weight: '',
        // height: '',
        // lg_cover: '',
        // xs_cover: '',
        // icon: '',
        // tag: '',
        // url: '',
        // update_user: 0,
        // item1: '', // 备用字段
        // keyword: '',
        school_id: 0,
        module: '',
        // sort: 0
      },
      dialogFrom: {
        id: '',
        title: '',
        sub_title: '',
        remark: '',
        d_level: 0,
        parent_id: 0,
        // is_menue: true, // 是否菜单
        // weight: '',
        // height: '',
        // lg_cover: '',
        // xs_cover: '',
        // icon: '',
        // tag: '',
        // url: '',
        // update_user: 0,
        // item1: '', // 备用字段
        // keyword: '',
        school_id: 0,
        module: ''
        // sort: 0
      },
      formLabelWidth: '130px',
      delDialog: false,
      delObj: {},
      permissionFormVisible: false,
      permissionForm: {
        id: 0,
        RoleIs: [0],
        UserIds: [0],
        OrgIds: [0]
      },
      total: 0,
      ModuleList: [],
      moduleValue: '',
      query:{
        HasRole: false,
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 50,
        Sort: [],
      },
    };
  },
  created() {
    // this.getDictionaryList()
    this.getDictionaryTree('RolePermissions',365).then(list=>{
      this.tableData = JSON.parse(JSON.stringify(list));
    });
  },
  mounted() {
    this.getModuleList();
    this.getDictionaryList();
  },
  methods: {
    
    // 添加
    add() {
      this.dialogFormVisible = true;
      this.dialogTitle = '添加字典';
      this.dialogFrom = {
        id: '',
        title: '',
        sub_title: '',
        remark: '',
        d_level: 0,
        parent_id: 0,
        school_id: 0,
        module: ''
      };
    },
    // 添加下级
    addSubordinate(e) {
      this.dialogFormVisible = true;
      let obj = e;
      this.dialogFrom = {
        id: '',
        title: '',
        sub_title: '',
        remark: '',
        d_level: obj.d_level + 1,
        school_id: obj.school_id,
        module: obj.module,
        parent_id: obj.id,
      };
      if(e.d_level >= 1) this.dialogFrom.title = e.title
      this.dialogTitle = '添加字典下级';
    },
    // 编辑
    edit(e) {
      this.dialogFormVisible = true;
      this.dialogTitle = '编辑字典';
      this.dialogFrom = e; 
    },
    // 提交
    submit() {
      let obj = this.dialogFrom;
      let dialogTitle = this.dialogTitle;
      if (dialogTitle === '添加字典' || dialogTitle === '添加字典下级') {
        this.API.addDictionary(obj).then(res => {
          if (res.success) {
            this.getDictionaryList();
            this.$message.success(res.message)
            this.dialogTitle.sub_title = ''
            this.dialogFormVisible = false;
          }
        });
      } else {
        this.API.editDictionary(obj).then(res => {
          if (res.success) {
            this.getDictionaryList();
            this.dialogFormVisible = false;
          }
        });
      }
    },
    // 删除
    handleClose() {
      this.delDialog = false;
    },
    delClick(e) {
      this.delDialog = true;
      this.delObj = e; 
    },
    del() {
      let obj = {
        ids: this.delObj.id.toString(), // 数组形式的ID 字符串 ,隔开
        status: -99, // 状态0 = Active (正常), -99 = Delete (删除), -1 = UnKnown (未知)
        module: this.delObj.module
      };
      this.API.changeDictionaryStatus(obj).then(res => {
        if (res.success) {
          this.delDialog = false;
          this.getDictionaryList();
        } 
      });
    },
    // 查询
    search() {
      this.query.PageIndex=1;
      this.getDictionaryList();
    },
    // 模块选择
    selChange(e) {
      this.getDictionaryList();
    },
    // 获取模块列表
    getModuleList() {
      let obj = {
        HasRole: false,
        getmodule: true,
        PageIndex: 1,
        PageSize: 100000,
      }
      this.API.getDictionaryList(obj).then(res => {
        res.data.rows.forEach(item=>{
          if(!this.ModuleList.includes(item.module)){
            this.ModuleList.push(item.module);
          }
        });
      })
    },
    
    // 获取列表
    getDictionaryList() {
      this.API.getDictionaryList(this.query).then(res => {
        if (res.success) {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        } 
      });
    },
    getDictionaryTree(module,parentId) {
      let obj = {
        module: module,
        parentId: parentId,
        limit: 1000,
      };
      let list=[];
      return new Promise((resovle,reject)=>{
        this.API.getDictionaryTree(obj).then(res=>{
          let result =  res.data;
          list = result;
          let hasChildrenList = result.filter(t=>t.leaf);
          if(hasChildrenList.length){
            Promise.all(hasChildrenList.map(t=>{
              return new Promise((resovle1,reject1)=>{
                this.getDictionaryTree(module,t.id).then(res1=>{
                  let index = list.findIndex(item=>item.id===t.id);
                  if(index>-1){
                    list[index].children = res1;
                  }
                });
              })
            })).then(()=>{
              resovle(list);
            });
          }else{
            resovle(list);
          }
        });
      });
      
    },
    handleClick(row) { 
    },
    // 分页
    handleSizeChange(val) { 
      this.pageSize = val
      this.getDictionaryList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDictionaryList()
    }
  }
};
</script>

<style scoped></style>
